<template>
	<AppLayout>
		<div class="container-1">
			<div class="content"></div>
			<div class="content-1"></div>
			<!-- <OrderCenterAll /> -->
			<div class="container">
				<div class="container-content">
					<div class="order-content">
						<!-- tab栏目区域 -->
						<ul class="tabBox">
							<p class="order-d">订单中心</p>
							<li class="tabItem" v-for="(item, index) in orderContent" :key="item.id + index"
								:class="item.num === currentActive ? 'active' : ''" @click.self="changeTab(item.num)"
								v-track:click.self="{event:item.title,page:'我的订单'}">
								{{ item.title }}
							</li>
							<p class="order-d">销售合同</p>
							<li class="tabItem" v-for="(item, index) in orderContentd" :key="item.id + index"
								:class="item.num === currentActive ? 'active' : ''" @click.self="changeTab(item.num)"
								v-track:click.self="{event:item.title,page:'我的订单'}">
								{{ item.title }}
							</li>
							<p class="afterSale">售后与保修</p>
							<li class="tabItem" v-for="(item, index) in orderContents" :key="item.id + index"
								:class="item.num == currentActive ? 'active' : ''" @click.self="changeTab(item.num)"
								v-track:click.self="{event:item.title,page:'我的订单'}">
								{{ item.title }}
							</li>
						</ul>
						<!-- 具体信息设置区域 -->
						<el-card shadow="never">
							<!-- 我的订单 -->
							<template v-if="currentActive === 0">
								<OrderList />
							</template>
							<!-- 售货地址管理 -->
							<template v-if="currentActive === 1">
								<DeliveryAddress />
							</template>
							<!-- 发票信息管理 -->
							<template v-if="currentActive === 2">
								<InvoiceInformation />
							</template>
							<!-- 售后列表 -->
							<template v-if="currentActive === 3">
								<AfterSaleList />
							</template>
							<!--售后申请  -->
							<template v-if="currentActive == 4">
								<ApplyAfter />
							</template>
							<template v-if="currentActive == 5">
								<Contract />
							</template>
							<template v-if="currentActive == 6">
								<ContractInfo />
							</template>
						</el-card>
					</div>
				</div>
			</div>
		</div>
	</AppLayout>
	<HomeBackup />
</template>

<script setup>
	import AppLayout from "@/components/AppLayout";
	import OrderCenterAll from "./components/OrderCenterAll.vue";
	import HomeBackup from "@/views/home/components/HomeBackup";
	import {
		ref,
		onMounted
	} from "vue";
	import OrderList from "./components/OrderList";
	import DeliveryAddress from "./components/DeliveryAddress";
	import InvoiceInformation from "./components/InvoiceInformation";
	import Contract from "./components/contract";
	import ContractInfo from "./components/contractInfo";
	import AfterSaleList from "../AfterSaleList/index";
	import ApplyAfter from "../AfterSaleList/components/ApplyAfter";
	import {
		useRoute
	} from "vue-router";
	import emitter from "@/utils/eventBus";
	const route = useRoute();
	const currentActive = ref(0);
	const temp = ref("");

	onMounted(() => {
		if (route.query.currentActive == 4) {
			currentActive.value = route.query.currentActive;
		}
	});
	emitter.on("jumpsale", (Fn) => {
		if (Fn.orderNumber) {
			currentActive.value = 4;
			temp.value = Fn.orderNumber;
			var stoptimer = setInterval(function() {
				if (temp.value != "") {
					emitter.emit("orId", {
						id: temp.value
					});
					temp.value = ""
				} else {
					clearInterval(stoptimer)
				}
			}, 1000);
		}
	});

	const orderContent = ref([{
			title: "我的订单",
			id: 1,
			num: 0
		},
		{
			title: "收货地址管理",
			id: 2,
			num: 1
		},
		{
			title: "发票信息管理",
			id: 3,
			num: 2
		},
	]);
	const orderContents = ref([{
			title: "售后申请",
			id: 5,
			num: 4
		},
		{
			title: "售后记录",
			id: 4,
			num: 3
		},
	]);
	const orderContentd = ref([{
			title: "我的合同",
			id: 6,
			num: 5
		},
		{
			title: "合同信息管理",
			id: 7,
			num: 6
		},
	]);
	const changeTab = (inx) => {
		currentActive.value = inx;
	};
</script>

<style lang="less" scoped>
	.container {
		width: 1920px;
		height: 100%;
		background-color: #f2f2f2;

		.container-content {
			width: 1200px;
			height: 100%;
			margin: 0 auto;

			.order-content {
				display: flex;

				.tabBox {
					width: 200px;
					height: 900px;
					background-color: #ffffff;
					padding-left: 38px;

					.order-d {
						font-size: 16px;
						font-weight: 400;
						color: #333333;
						padding-top: 40px;
						margin-bottom: 30px;
					}

					.tabItem {
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						cursor: pointer;
						margin-top: 20px;
					}

					.active {
						//background: lighten(#ce1200, 50%);
						color: #ce1200;
					}

					:hover {
						//background: lighten(#ce1200, 50%);
						color: #ce1200;
					}

					.afterSale {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						margin-top: 59px;
					}
				}

				.el-card {
					width: 980px;
					// height: 1258px;
					margin-left: 20px;
					margin-bottom: 60px;

					::v-deep .el-card__body {
						padding-top: 45px;
						padding-left: 48px;
						padding-bottom: 34px;
					}
				}
			}
		}
	}

	.container-1 {
		width: 1920px;
		height: 100%;
		background-color: #f2f2f2;
		margin: 0 auto;

		.content {
			width: 1920px;
			height: 70px;
			background-color: #fff;
		}

		.content-1 {
			width: 1920px;
			height: 20px;
			background-color: #f2f2f2;
		}
	}
</style>